import {createPortal} from 'react-dom';
import {useRef, useState} from 'react';
import './SportSupersportIntro.scss';
import {useConfigurationStore, useGlobalStore} from '../../store/store';
import {AnimatePresence, motion} from 'framer-motion';
import DATA from '../../data/data.json';
import {FRAMER_MOTION_VARIANT_CONTAINER, FRAMER_MOTION_VARIANT_FROM_BOTTOM} from '../../utilities/framerMotionVariants';
import {useRightWindowCamOffset} from "../../hooks/useRightWindowCamOffset";
import {BsArrowLeftShort} from "react-icons/bs";
import {ELanguage, EPackage} from "../../types/types";
import Lightbox from "../Lightbox/Lightbox";
import "../LightboxContentPackageDetail/LightboxContentPackageDetail.scss";
import {returnConfigurationAfterPackageChange, returnConfigurationWithUpdatedPrices, returnFormattedLowestPrice} from "../../utilities/utilities";
import PackageIcon from "../PackageIcon/PackageIcon";
import Accordeon from "../Accordeon/Accordeon";

interface ISportSupersportIntroProps {}

function SportSupersportIntro(props: ISportSupersportIntroProps) {
    const sportSupersportIntroWindowRef = useRef<HTMLDivElement>(null);
    const {configuration, basicEquipmentPackage} = useConfigurationStore(state => state);
    const {isGerman, isEURTerritory, currentCurrency, currentLanguage} = useGlobalStore(state => state);
    const {width: viewportWidth, height: viewportHeight} = useGlobalStore((state) => state.viewport);
    const [showDetailedInformation, setShowDetailedInformation] = useState(false);

    useRightWindowCamOffset(sportSupersportIntroWindowRef, viewportWidth, viewportHeight);
    
    const handlePackageClick = (packageType: EPackage) => {
        if(packageType === basicEquipmentPackage) return;
        
        useConfigurationStore.setState({
            basicEquipmentPackage: packageType,
            configuration: returnConfigurationWithUpdatedPrices(returnConfigurationAfterPackageChange(configuration, packageType), packageType, currentCurrency)
        });
    };

    return (
        <motion.div variants={FRAMER_MOTION_VARIANT_CONTAINER} initial={'initial'} animate={'animate'} exit={'exit'} className={'sportSupersportIntro'}>
            <div ref={sportSupersportIntroWindowRef} className={'sportSupersportIntro__inner'}>
                <motion.div variants={FRAMER_MOTION_VARIANT_FROM_BOTTOM} className={'sportSupersportIntro__title'}>
                    <h1 dangerouslySetInnerHTML={{__html: DATA.locale.sportSupersportIntro.headlineHtml[currentLanguage]}}></h1>
                </motion.div>
                <motion.div variants={FRAMER_MOTION_VARIANT_FROM_BOTTOM} className={'sportSupersportIntro__content'}>
                    <div className={'sportSupersportIntro__text'} dangerouslySetInnerHTML={{__html: DATA.locale.sportSupersportIntro.copyHtml[currentLanguage]}}></div>
                    <div className={'sportSupersportIntro__tiles'}>
                        <div className={`sportSupersportIntro__tiles-item`}>
                            <div className={`sportSupersportIntro__tiles-item-inner ${basicEquipmentPackage === EPackage.SPORT ? "sportSupersportIntro__tiles-item-inner--active" : ""}`} onClick={() => {handlePackageClick(EPackage.SPORT)}}>
                                <h1><PackageIcon type={EPackage.SPORT}/><span>Sport</span></h1>
                                <div dangerouslySetInnerHTML={{__html: DATA.locale.sportSupersportIntro.sportHtml[currentLanguage]}}/>
                                <h2 dangerouslySetInnerHTML={{__html: DATA.locale.priceFrom[currentLanguage] + "<br/><span>" + returnFormattedLowestPrice(EPackage.SPORT, isEURTerritory, isGerman) + "</span>"}}/>
                            </div>
                        </div>
                        <div className={'sportSupersportIntro__tiles-item'}>
                            <div className={`sportSupersportIntro__tiles-item-inner ${basicEquipmentPackage === EPackage.SUPERSPORT ? "sportSupersportIntro__tiles-item-inner--active" : ""}`} onClick={() => {handlePackageClick(EPackage.SUPERSPORT)}}>
                                <h1><PackageIcon type={EPackage.SUPERSPORT}/><span>Supersport</span></h1>
                                <div dangerouslySetInnerHTML={{__html: DATA.locale.sportSupersportIntro.supersportHtml[currentLanguage]}}/>
                                <h2 dangerouslySetInnerHTML={{__html: DATA.locale.priceFrom[currentLanguage] + "<br/><span>" + returnFormattedLowestPrice(EPackage.SUPERSPORT, isEURTerritory, isGerman) + "</span>"}}/>
                            </div>
                        </div>
                    </div>
                    <div className={'sportSupersportIntro__button-content'}>
                        <button
                            className={'btn'}
                            onClick={() => {setShowDetailedInformation(true)}}
                        >
                            {DATA.locale.whatsTheDifference[currentLanguage]}
                        </button>
                        <button
                            className={'btn btn--main'}
                            onClick={() => {
                                useGlobalStore.setState(state => ({currentStepIndex: state.currentStepIndex + 1}));
                            }}
                        >
                            {DATA.locale.continue[currentLanguage]}
                        </button>
                        <button
                            className={"sportSupersportIntro__back-button"}
                            onClick={() => {
                                useGlobalStore.setState((state) => ({
                                    currentStepIndex: state.currentStepIndex - 1,
                                }));
                            }}
                        >
                            <BsArrowLeftShort/>
                            {DATA.locale.back[currentLanguage]}
                        </button>
                    </div>
                </motion.div>
            </div>
            {createPortal(
                <AnimatePresence>
                    {showDetailedInformation && (
                        <Lightbox handleCloseButtonClick={() => setShowDetailedInformation(false)} title={DATA.locale.packageDifferences.title[currentLanguage]}>
                            <div className={'lightbox-content-package-detail'}>
                                {currentLanguage === ELanguage.EN && (
                                    <>
                                        <div className={'lightbox-content-package-detail__text'}>
                                            <p>While the STERK 31 SUPERSPORT shines with uncompromising performance and luxurious equipment, the STERK 31 SPORT focuses on the essentials.</p>
                                            <p>With efficient engine performance, specifically adapted equipment and reduced electronics, it offers an unbeatable price-performance ratio and therefore the perfect entry into the SUPERSPORT class. Experience the essence of pure riding pleasure with the STERK 31 SPORT.</p>
                                            <p>The design of the STERK 31 SUPERSPORT combines state-of-the-art technology with luxurious materials. The impressive performance and exclusive elegance meet the highest demands and set new standards in the SUPERSPORTBOAT class.</p>
                                        </div>
                                        <Accordeon titleHtml={"Technical Data"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Sports</td>
                                                    <td>Supersport</td>
                                                </tr>
                                                <tr>
                                                    <td>Made in Germany</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Made in Poland</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Declaration of cConformity</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>CECat.B = 6 Pax</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>CECat.C = 9 Pax</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Fuel tank = 347/530 l (92/140 US.Gal)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Total length = 9,28 m (30,4 ft)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Length incl. Motor = 9,73 m (32 ft)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Width = 2,99 m (9,8 ft)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Weight w/o engines = 2620 kg (5776 lbs)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Draft incl. engine = 0,84 m (2,8 ft)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Air draft incl. t-top = 2,4 m (7,9 ft)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Air draft excl. t-top = 1,87 m (6,2 ft)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Max. load = 4250 kg (9370 lbs)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>v/Cruise = 30 knots (34 mph)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>v/Max w/o t-top= ±60 knots (69 mph)</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>v/Max w/o t-top= ±50 knots (58 mph)</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Power max. = 2 x 257.4 kW (2 x 350 hp)</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Power max. = 2 x 184.9 kW (2 x 250 hp)</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                        <Accordeon titleHtml={"Comfort"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Sports</td>
                                                    <td>Supersport</td>
                                                </tr>
                                                <tr>
                                                    <td>Storage space at the bow</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Bow U seat cushion</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Rear sofa with grab handles</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Sun lounger aft</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Large storage space with front door in the control panel</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>7 cup holders</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Console cabin as storage space with windows</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Console cabin as storage space with hinged side windows</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Rear cabin as storage space under the sofa with side windows (RC)</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Rear cabin as storage space under the sofa with hinged side windows</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                        <Accordeon titleHtml={"Materials"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Sports</td>
                                                    <td>Supersport</td>
                                                </tr>
                                                <tr>
                                                    <td>Gelcoat color white</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Silvertex cushion set, champagne</td>
                                                    <td>o</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Polished stainless steel on starboard and port side with logo</td>
                                                    <td>o</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Silver foiling on starboard and port side with logo</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Spradling Nautilus Upholstery Cover, Almond</td>
                                                    <td>✓</td>
                                                    <td>o</td>
                                                </tr>
                                                <tr>
                                                    <td>Spradling Silvertex Upholstery Cover, Champagne</td>
                                                    <td>o</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>EVA Soft Teak Deck (natural or gray)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Teak lattice floor in wet room</td>
                                                    <td>o</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>King plastic floor in the wet room</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                        <Accordeon titleHtml={"Electric"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Sports</td>
                                                    <td>Supersport</td>
                                                </tr>
                                                <tr>
                                                    <td>Q double 10 inch chartplotter with 4G modem, NMEA, Q App</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Main control panel</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Main wiring harness</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>El. control panel</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Battery wiring harness</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>3 pcs. fuse box</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>El. package with switch panel (10 switches with fuses)</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>LED deck lighting</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Bilge pump system consisting of 3 electric pumps</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>El. horn</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                        <Accordeon titleHtml={"Equipment"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Sports</td>
                                                    <td>Supersport</td>
                                                </tr>
                                                <tr>
                                                    <td>Original Sterk leather steering wheel</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Ergonomic driver and passenger seat, swivelling with standing function</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Windscreen made of high quality plexiglass with frame and coaming</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Stainless steel railing</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>4 fold-out stainless steel cleats 180 mm (bow, stern)</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>4 fixed stainless steel cleats 205 mm (bow, stern)</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2 armrests in cockpit for driver and passenger</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>CE certified 347 litre fuel tank, fully installed with CE blower</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>1 manual bilge pump in fuel tank area</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Stainless steel drain spout</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Brass drain spout</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Folding stainless steel bath ladder with two integrated handles for easy exit</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Folding stainless steel bathing ladder with two handles for easy exit on the platform</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Four watertight hatches on the top of the bracket</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Four hatches on the top of the bracket</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                        <Accordeon titleHtml={"Engine Options"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>SPORT</td>
                                                    <td>SUPERSPORT</td>
                                                </tr>
                                                <tr>
                                                    <td>Max. 2 x 350 hp – 60 knots</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Max. 2 x 250 hp – 54 knots</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2x YAMAHA F/LF200XSA</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>2x YAMAHA F/LF200XSA</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>2x YAMAHA F/LF250XSB</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>2x YAMAHA F/LF300XSB</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>2x YAMAHA F350USB</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>1x YAMAHA F350USB</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>1x YAMAHA XF400USA</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>1x YAMAHA XF450USA</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Single &amp; twin installation incl. rigging</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                    </>
                                )}
                                {currentLanguage === ELanguage.DE && (
                                    <>
                                        <div className={'lightbox-content-package-detail__text'}>
                                            <p>Während die STERK 31 SUPERSPORT mit kompromissloser Leistung und luxuriöser Ausstattung glänzt, setzt die STERK 31 SPORT auf das Wesentliche.</p>
                                            <p>Mit effizienter Motorleistung, gezielt angepasster Ausstattung und reduzierter Elektronik bietet sie ein unschlagbares Preis-Fahrspaß-Verhältnis und damit den perfekten Einstieg in die Supersport-Klasse. Erleben Sie die Essenz puren Fahrvergnügens mit
                                                der STERK 31 SPORT.</p>
                                            <p>Das Design der STERK 31 SUPERSPORT kombiniert modernste Technologie mit luxuriösen Materialien. Die beeindruckende Performance und exklusive Eleganz erfüllen höchste Ansprüche und setzen neue Maßstäbe in der Supersport Boot Klasse.</p>
                                        </div>
                                        <Accordeon titleHtml={"Technische Daten"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                <td></td>
                                                    <td>Sport</td>
                                                    <td>Supersport</td>
                                                </tr>
                                                <tr>
                                                    <td>Made in Germany</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Made in Poland</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Konformitätserklärung</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>CEKat.B = 6 Pax</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>CEKat.C = 9 Pax</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Kraftstofftank = 347/530 l</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Länge über alles = 9,28 m</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Gesamtlänge inkl. Motor = 9,73 m</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Breite = 2,99 m</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Gewicht ohne Motoren = 2620 kg</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Tiefgang inkl. Motor = 0,84 m</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Durchfahrtshöhe mit Verdeck = 2,4 m</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Durchfahrtshöhe o. Verdeck = 1,87 m</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Max. Belastung = 4250 kg</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>v/Kreuzfahrt = 30 Knoten (55 km/h)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>v/Max o. T-Top = ±60 Knoten (112 km/h)</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>v/Max o. T-Top = ±50 Knoten (92 km/h)</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Leistung max. = 2 x 257,4 kW (2 x 350 PS)</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Leistung max. = 2 x 184,9 kW (2 x 250 PS)</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                        <Accordeon titleHtml={"Komfort"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Sport</td>
                                                    <td>Supersport</td>
                                                </tr>
                                                <tr>
                                                    <td>Stauraum am Bug</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Bug U-Sitzkissen</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Hecksofa mit Haltegriffen</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Sonnenliege achtern</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Großer Stauraum mit Fronttür in der Steuerkonsole</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>7 Getränkehalter</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Konsolenkabine als Stauraum mit Fenstern</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Konsolenkabine als Stauraum mit aufklappbaren Seitenfenstern</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Heckkabine als Stauraum unter dem Sofa mit Seitenfenstern (RC)</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Heckkabine als Stauraum unter dem Sofa mit aufklappbaren Seitenfenstern</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                        <Accordeon titleHtml={"Materialien"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Sport</td>
                                                    <td>Supersport</td>
                                                </tr>
                                                <tr>
                                                    <td>Gelcoat Farbe weiß</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Kissen Set Silvertex, champagner</td>
                                                    <td>o</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Poliertes Edelstahl an Steuer- und Backbordseite mit Logo</td>
                                                    <td>o</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Silberne Folierung auf Steuer- und Backbordseite mit Logo</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Spradling Nautilus Polsterbezug, Almond</td>
                                                    <td>✓</td>
                                                    <td>o</td>
                                                </tr>
                                                <tr>
                                                    <td>Spradling Silvertex Polsterbezug, Champagner</td>
                                                    <td>o</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>EVA Soft Teak Deck (natural oder grau)</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Teakholz-Gitterboden im Nasszelle</td>
                                                    <td>o</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>King Kunststoffboden in der Nasszelle</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                        <Accordeon titleHtml={"Elektrik"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Sport</td>
                                                    <td>Supersport</td>
                                                </tr>
                                                <tr>
                                                    <td>Q Doppel 10 Zoll Kartenplotter mit 4G Modem, NMEA, Q App</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Hauptschalttafel</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Hauptkabelbaum</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>El. Schalttafel</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Batterie-Kabelbaum</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>3 Stk. Sicherungskasten</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>El. Paket mit Schalttafel (10 Schalter mit Sicherungen)</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>LED Deck Beleuchtung</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Bilgepumpenanlage bestehend aus 3 el. Pumpen</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>El. Horn</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                        <Accordeon titleHtml={"Ausstattung"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>Sport</td>
                                                    <td>Supersport</td>
                                                </tr>
                                                <tr>
                                                    <td>Original Sterk-Lenkrad aus Leder</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Ergonomischer Fahrer- und Beifahrersitz, drehbar mit Stehfunktion</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Windschutzscheibe aus hochwertigem Plexiglas mit Rahmen und Süllrand</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Reling aus Edelstahl</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>4&nbsp; ausklappbare Edelstahlklampen 180 mm (Bug, Heck)</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>4&nbsp; feststehende Edelstahlklampen 205 mm (Bug, Heck)</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2 Armlehnen im Cockpit für Fahrer und Beifahrer</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>CE-zertifizierter 347 l Kraftstofftank, fertig installiert mit Gebläse nach CE</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>1 manuelle Bilgepumpe im Kraftstofftank-Bereich</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Ablaufstutzen aus Edelstahl</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Ablaufstutzen aus Messing</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Klappbare Edelstahl-Badeleiter mit zwei Griffen für einfachen Ausstieg integriert</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Klappbare Edelstahl-Badeleiter mit zwei Griffen für einfachen Ausstieg auf der Plattform</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>Vier wasserdichte Luken auf der Oberseite der Bracket</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Vier Luken auf der Oberseite der Bracket</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                        <Accordeon titleHtml={"Motorkonfiguration"}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td>SPORT</td>
                                                    <td>SUPERSPORT</td>
                                                </tr>
                                                <tr>
                                                    <td>Max. 2 x 350 PS – 60 Knoten</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Max. 2 x 250 PS – 54 Knoten</td>
                                                    <td>✓</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2x YAMAHA F/LF200XSA</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>2x YAMAHA F/LF200XSA</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>2x YAMAHA F/LF250XSB</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>2x YAMAHA F/LF300XSB</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>2x YAMAHA F350USB</td>
                                                    <td></td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>1x YAMAHA F350USB</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>1x YAMAHA XF400USA</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>1x YAMAHA XF450USA</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                <tr>
                                                    <td>Single- &amp; Twin-Installation incl. Rigging</td>
                                                    <td>✓</td>
                                                    <td>✓</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </Accordeon>
                                    </>
                                )}
                                <button className={'btn'} onClick={() => {setShowDetailedInformation(false)}}>{DATA.locale.continue[currentLanguage]}</button>
                            </div>
                        </Lightbox>
                    )}
                </AnimatePresence>,
                document.getElementsByTagName('body')[0])
            }
        </motion.div>
    );
}

export default SportSupersportIntro;
