import {create} from 'zustand';
import {devtools, subscribeWithSelector} from 'zustand/middleware';
import {ConfigurationState, ECurrencies, ELanguage, EPackage, FormFieldState, GlobalState, IConfigurationCategory, ISceneActionHotspot, isEPackage, SceneActionHotspotsState, TActiveOverride, ThreeDReference} from '../types/types';
import DATA from '../data/data.json';
import {IFormFieldCheckbox, IFormFieldGeneric, IFormFieldRadioGroup, IFormFieldSelect} from '../types/formTypes';
import {STEPS_AFTER_CONFIGURATION, STEPS_BEFORE_CONFIGURATION} from '../utilities/globals';
import {applyActiveOverrides, createQueryString, CustomUrlParams, prepareFormFieldData, processConfigurationCategories, processSceneActionHotspots, transformQueryString} from './storeUtilities';
import {returnConfigurationAfterPackageChange, returnConfigurationWithUpdatedPrices} from "../utilities/utilities";

/******************************************************************
 * Initialization
 *****************************************************************/


const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
let isEURTerritory = true;

//Array of all Timezones that belong to the US, Canada, Australia or New Zealand
//If you want to check what timezones there are, use Intl.supportedValuesOf('timeZone')
const timeZoneUSDArray = [
    'Australia', // AUSTRALIA
    'Pacific/Auckland', //NEW ZEALAND
    'Pacific/Chatham',
    'America/Adak', //USA
    'America/Anchorage',
    'America/Boise',
    'America/Chicago',
    'America/Denver',
    'America/Detroit',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indianapolis',
    'America/Juneau',
    'America/Kentucky/Monticello',
    'America/Los_Angeles',
    'America/Louisville',
    'America/Menominee',
    'America/Metlakatla',
    'America/New_York',
    'America/Nome',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Phoenix',
    'America/Sitka',
    'America/Yakutat', 
    'America/Atikokan', //CANADA
    'America/Blanc-Sablon',
    'America/Cambridge_Bay',
    'America/Creston',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Edmonton',
    'America/Fort_Nelson',
    'America/Glace_Bay',
    'America/Goose_Bay',
    'America/Halifax',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Moncton',
    'America/Nipigon',
    'America/Pangnirtung',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Resolute',
    'America/St_Johns',
    'America/Swift_Current',
    'America/Thunder_Bay',
    'America/Toronto',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yellowknife'
];

//Loop through the array and check if currentTimeZone starts with any of the values. If yes, set isEURTerritory to false.
for (let i = 0; i < timeZoneUSDArray.length; i++) {
    if(currentTimeZone.startsWith(timeZoneUSDArray[i])) {
        isEURTerritory = false;
        break;
    }
}
const isGerman = currentTimeZone.endsWith('Berlin');
let currentCurrency: ECurrencies = ECurrencies.EUR;
if(isEURTerritory && isGerman) {
    currentCurrency = ECurrencies.EUR;
}
else if(isEURTerritory && !isGerman) {
    currentCurrency = ECurrencies.EUR;
}
else if(!isEURTerritory) {
    currentCurrency = ECurrencies.USD;
}

console.warn("You current timezone is: ", currentTimeZone);
console.warn("Sterk configurator assumes you are located in Germany: ", isGerman);
console.warn("STerk configurator assumes you are located in a Timezone that needs to use EUR: ", isEURTerritory); 

// URL Params
const urlSearchParams = new CustomUrlParams(window.location.search);
let activePackage: EPackage = EPackage.SPORT;
if(urlSearchParams.params[DATA.saveConfiguration.queryParamNamePackage]) {
    activePackage = isEPackage(urlSearchParams.params[DATA.saveConfiguration.queryParamNamePackage]) ? urlSearchParams.params[DATA.saveConfiguration.queryParamNamePackage] as EPackage : EPackage.SPORT;
}
let activeOverrides: TActiveOverride[] | null = null;
if (urlSearchParams.params[DATA.saveConfiguration.queryParamNameConfiguration]) {
    activeOverrides = transformQueryString(urlSearchParams.params[DATA.saveConfiguration.queryParamNameConfiguration]);
}

// Process data
const processedConfiguration = processConfigurationCategories(DATA.configurationCategories as IConfigurationCategory[]);
const processedConfigurationWithActiveOverrides = applyActiveOverrides(processedConfiguration, activeOverrides);
const processedConfigurationWithActiveOverridesAndPackageValidation = returnConfigurationAfterPackageChange(processedConfigurationWithActiveOverrides, activePackage);
const processedConfigurationWithCalculatedPrices = returnConfigurationWithUpdatedPrices(processedConfigurationWithActiveOverridesAndPackageValidation, activePackage, ECurrencies.EUR);

/******************************************************************
 * Configuration Store
 *****************************************************************/
export const useConfigurationStore = create<ConfigurationState>()(devtools(set => ({
    configuration: processedConfigurationWithCalculatedPrices, 
    basicEquipmentPackage: activePackage
}), {name: 'Configuration'}));

/**
 * Subscribe to changes in the configuration and update the query-string accordingly
 */
useConfigurationStore.subscribe((state, prevState) => {
    let queryString = createQueryString(state.configuration);
    let encodedQueryString = encodeURIComponent(queryString);
    const UrlParams = new CustomUrlParams(window.location.search);
    UrlParams.params[DATA.saveConfiguration.queryParamNameConfiguration] = encodedQueryString;
    UrlParams.params[DATA.saveConfiguration.queryParamNamePackage] = state.basicEquipmentPackage;
    window.history.replaceState(null, '', window.location.pathname + UrlParams.returnQueryString());
});

/******************************************************************
 * Global Store
 *****************************************************************/
export const useGlobalStore = create<GlobalState>()(devtools(subscribeWithSelector(set => ({
            isGerman: isGerman,
            isEURTerritory: isEURTerritory,
            currentStepIndex: 0,
            highestVisitedStepIndex: activeOverrides === null ? 0 : DATA.configurationCategories.length + STEPS_BEFORE_CONFIGURATION + STEPS_AFTER_CONFIGURATION - 1,
            isLoading: true,
            loadedPercentage: 0,
            currentLanguage: isGerman ? ELanguage.DE : ELanguage.EN,
            currentCurrency: currentCurrency,
            contactWindowState: 'form',
            emailSendStatusCode: 999999,
            camOffset: {
                x: 0,
                y: 0,
            },
            viewport: {
                width: document.body.clientWidth,
                height: window.innerHeight,
            },
            optionWasSelectedOnce: activeOverrides !== null,
            saveConfigurationOverlayIsOpen: false,
            summaryOverlayIsOpen: false,
            fullscreenIsActive: document.fullscreenElement !== null,
            baseUrl: window.location.origin,
        })),{name: 'Global'}));

/******************************************************************
 * SceneActionHotspots Store
 *****************************************************************/
export const useSceneActionHotspotsStore = create<SceneActionHotspotsState>()(devtools(set => ({sceneActionHotspots: processSceneActionHotspots(DATA.sceneActionHotspots as ISceneActionHotspot[])}), {name: 'SceneActionHotspots'}));

/******************************************************************
 * ThreeD Reference
 *****************************************************************/
export const useThreeDReference = create<ThreeDReference>()(devtools(set => ({
            threeD: null,
        }),{name: 'ThreeDReference'}));

/******************************************************************
 * Formfield Store
 *****************************************************************/
export const useFormFieldsStore = create<FormFieldState>()(devtools(set => ({
        formFields: prepareFormFieldData(DATA.formFields as (IFormFieldGeneric | IFormFieldSelect | IFormFieldRadioGroup | IFormFieldCheckbox)[], ELanguage.EN),
    })));
