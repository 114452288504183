import React, { useEffect, useRef } from 'react';
import './App.scss';
import Header from '../Header/Header';
import ThreeDCanvas from '../ThreeDCanvas/ThreeDCanvas';
import OptionWindow from '../OptionWindow/OptionWindow';
import { useGlobalStore } from '../../store/store';
import { STEPS_AFTER_CONFIGURATION, STEPS_BEFORE_CONFIGURATION } from '../../utilities/globals';
import DATA from '../../data/data.json';
import Intro from '../Intro/Intro';
import Contact from '../Contact/Contact';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { AnimatePresence } from 'framer-motion';
import Lightbox from '../Lightbox/Lightbox';
import LightboxContentSimple from '../LightboxContentSimple/LightboxContentSimple';
import SportSupersportIntro from "../SportSupersportIntro/SportSupersportIntro";
import LightboxContentSummary from "../LightboxContentSummary/LightboxContentSummary";
import {ECurrencies, ELanguage} from "../../types/types";

function App() {
    const resizeTimeoutId = useRef<undefined | ReturnType<typeof setTimeout>>();
    const {currentStepIndex,isLoading,saveConfigurationOverlayIsOpen, currentLanguage, summaryOverlayIsOpen} = useGlobalStore(state => ({
        currentStepIndex: state.currentStepIndex,
        isLoading: state.isLoading,
        saveConfigurationOverlayIsOpen: state.saveConfigurationOverlayIsOpen,
        currentLanguage: state.currentLanguage,
        summaryOverlayIsOpen: state.summaryOverlayIsOpen,
    }));
    
    const optionWindowShouldRender = currentStepIndex > STEPS_BEFORE_CONFIGURATION - 1 && currentStepIndex < DATA.configurationCategories.length + STEPS_BEFORE_CONFIGURATION;
    const contactFormShouldRender = currentStepIndex === DATA.configurationCategories.length + STEPS_BEFORE_CONFIGURATION + STEPS_AFTER_CONFIGURATION - 1;
    const resizeHandler = () => {
        clearTimeout(resizeTimeoutId.current);
        resizeTimeoutId.current = setTimeout(() => {
            useGlobalStore.setState({
                viewport: {
                    width: document.body.clientWidth,
                    height: window.innerHeight,
                },
            });
        }, 100);
    }
    const debugKeypressHandler = (e: KeyboardEvent) => {
        if(e.key === 'd') {
            useGlobalStore.setState({
                isGerman: true,
                isEURTerritory: true,
                currentCurrency: ECurrencies.EUR,
                currentLanguage: ELanguage.DE
            });
        }
        if(e.key === 'e') {
            useGlobalStore.setState({
                isGerman: false,
                isEURTerritory: true,
                currentCurrency: ECurrencies.EUR,
                currentLanguage: ELanguage.EN
            });
        }
        if(e.key === 'u') {
            useGlobalStore.setState({
                isGerman: false,
                isEURTerritory: false,
                currentCurrency: ECurrencies.USD,
                currentLanguage: ELanguage.EN
            });
        }
    }
    
    useEffect(() => {
        useGlobalStore.setState({
            viewport: {
                width: document.body.clientWidth,
                height: window.innerHeight,
            }
        });
        window.addEventListener('resize', resizeHandler);
        //window.addEventListener('keypress', debugKeypressHandler);
        
        return () => {
            window.removeEventListener('resize', resizeHandler);
            //window.removeEventListener('keypress', debugKeypressHandler);
        }}, []);
    
    useEffect(() => {
        function onFullscreenChange() {
            useGlobalStore.setState({ fullscreenIsActive: document.fullscreenElement !== null });
        }
        document.addEventListener('fullscreenchange', onFullscreenChange);
        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);

    return (
        <div className='app'>
            <ThreeDCanvas key={'canvas'} />
            {isLoading && <LoadingIndicator />}
            <AnimatePresence>
                {!isLoading && (
                    <>
                        <Header key={'header'} />
                        {/*<button className={"testbutton"} onClick={handleTestButtonClick}>Test</button>*/}
                        <AnimatePresence>{currentStepIndex === 0 && <Intro key={'intro'} />}</AnimatePresence>
                        <AnimatePresence>{currentStepIndex === 1 && <SportSupersportIntro key={'sportSupersportIntro'} />}</AnimatePresence>
                        <AnimatePresence>{optionWindowShouldRender && <OptionWindow key={'optionWindow'} />}</AnimatePresence>
                        <AnimatePresence>{contactFormShouldRender && <Contact key={'contactForm'} />}</AnimatePresence>
                        <AnimatePresence>
                            {saveConfigurationOverlayIsOpen && (
                                <Lightbox 
                                    handleCloseButtonClick={() => {useGlobalStore.setState({ saveConfigurationOverlayIsOpen: false });}}
                                    title={DATA.locale.saveConfigurationTitle[currentLanguage]}
                                >
                                    <LightboxContentSimple />
                                </Lightbox>
                            )}
                        </AnimatePresence>
                        <AnimatePresence>
                            {summaryOverlayIsOpen && (
                                <Lightbox handleCloseButtonClick={() => {useGlobalStore.setState({summaryOverlayIsOpen: false})}} title={DATA.locale.summary[currentLanguage]}>
                                    <LightboxContentSummary/>
                                </Lightbox>
                            )}
                        </AnimatePresence>
                    </>
                )}
            </AnimatePresence>
        </div>
    );
}

export default App;
